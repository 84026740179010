<script lang="ts" setup>
// Dependencies - Vendor
import { computed, defineAsyncComponent, onErrorCaptured } from 'vue';

// Dependencies - Framework
import { useMainStore } from '@/stores/mainStore';
import { DEFINE_ASYNC_COMPONENT_TIMEOUT, handleError } from '@/globals';

// Dependencies - Component
import ComponentUnavailableMask from '@/components/ComponentUnavailableMask.vue';
import ComponentLoadingMask from '@/components/ComponentLoadingMask.vue';

// Dependencies - Component - Asynchronous
const AboutPanel = defineAsyncComponent({
    loader: () => import('./about/AboutPanel.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT,
    onError(error, retry, fail, attempts) {
        console.log('defineAsyncComponent.onError', error, retry, fail, attempts);
    }
});
const AccountPanel = defineAsyncComponent({
    loader: () => import('./account/AccountPanel.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT
});
const ChatPanel = defineAsyncComponent({
    loader: () => import('./chat/ChatPanel.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT
});
const DocumentationPanel = defineAsyncComponent({
    loader: () => import('./documentation/DocumentationPanel.vue'),
    errorComponent: ComponentUnavailableMask,
    loadingComponent: ComponentLoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT
});

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const toolDrawerPanelId = computed(() => mainStore.toolDrawerPanelId);
const toolDrawerStateId = computed(() => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed(() => mainStore.toolDrawerWidth);

// Lifecycle Event Handlers (ErrorCaptured
onErrorCaptured((error) => {
    if (error.message.startsWith('Failed to fetch dynamically imported module:')) {
        return false;
    }
    return handleError(error, { locator: 'ToolDrawer.1' });
});
</script>

<template>
    <div
        class="h-[calc(100%-56px)] max-w-[calc(100%-16px)] flex-col border-b border-l border-r border-l-line-light bg-surface-light dark:border-l-line-dark dark:bg-surface-dark"
        :class="
            toolDrawerStateId === 'floating'
                ? 'absolute right-0 border-b-line-light border-r-line-light dark:border-b-line-dark dark:border-r-line-dark'
                : 'border-b-transparent border-r-transparent dark:border-b-transparent dark:border-r-transparent'
        "
        :style="{ display: toolDrawerPanelId ? 'flex' : 'none', width: `${toolDrawerWidth}px` }"
    >
        <hr class="mx-4 flex-none border-line-light dark:border-line-dark" />

        <div class="relative flex-1">
            <Transition name="fade">
                <AboutPanel v-if="toolDrawerPanelId === 'about'" class="absolute h-[calc(100%-1px)]" />
                <AccountPanel v-else-if="toolDrawerPanelId === 'account'" class="absolute h-[calc(100%-1px)]" />
                <ChatPanel v-else-if="toolDrawerPanelId === 'chat'" class="absolute h-[calc(100%-1px)]" />
                <DocumentationPanel v-else-if="toolDrawerPanelId === 'documentation'" class="absolute h-[calc(100%-1px)]" />
            </Transition>
        </div>
    </div>
</template>
