// Dependencies - Vendor
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';

// Dependencies - Framework
import '@/assets/main.scss'; // Global application styles.
import { initTranslations } from '@/locales';
import router from '@/router';
import { handleError, initAppMonitor } from '@/globals';

// Dependencies - Component
import App from '@/App.vue'; // Root component.

// Application - Initialise
try {
    // Create a new Vue application instance and register plugins.
    const application = createApp(App); // Create the main Vue instance using the root component.
    application.use(createPinia()); // Register Pinia for centralized state management. 'useMainStore' in 'src/stores/mainStore.ts' is then available for use.
    application.use(VueQueryPlugin); // Register Vue Query for managing data fetching, caching, and synchronization.
    application.use(router); // Register the router instance to handle navigation.

    // Initialize translations before proceeding with application initialization.
    initTranslations(application).then(() => {
        try {
            // Asynchronously load and initialize the application monitor without blocking application startup.
            // 'src/global.ts' provides helpers for communicating with the application monitor. These are required as the monitor may not have completed loading if we access it during the startup process.
            // The application monitor is also responsible for asynchronously loading the session manager.
            import('@/appMonitor').then((module) => initAppMonitor(module));

            application.mount('#app'); // Mount the application to the DOM. This will begin before the application monitor and session manager have completed loading.
        } catch (error) {
            handleError(error, { locator: 'Application Initialisation [main.2]' }); // Report error during initialisation.
        }
    });
} catch (error) {
    handleError(error, { locator: 'Application Initialisation [main.1]' }); // Report error during initialisation.
}
