<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';
import { useFullscreen } from '@vueuse/core';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Components
import Button from '@/components/Button.vue';

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const appearance = computed(() => mainStore.appearance);
const { isFullscreen, isSupported: isFullscreenSupported, toggle: toggleFullscreen } = useFullscreen();

// UI Event Handlers - Toggle Appearance
const toggleAppearance = () => {
    switch (appearance.value.id) {
        case 'dark':
            return mainStore.applyAppearanceUsingId('system');
        case 'light':
            return mainStore.applyAppearanceUsingId('dark');
        default:
            return mainStore.applyAppearanceUsingId('light');
    }
};
</script>

<template>
    <!-- Toggle Fullscreen -->
    <Button :disabled="!isFullscreenSupported" type="option" @click="toggleFullscreen()">
        <div class="flex items-center">
            <div v-html="isFullscreen ? actionIcons.exitFullscreenSVG : actionIcons.enterFullscreenSVG" class="flex h-9 w-9 items-center justify-center" />
        </div>
    </Button>

    <!-- Toggle Appearance -->
    <Button type="option" @click="toggleAppearance()">
        <div class="flex items-center">
            <div v-html="actionIcons[`appear${appearance.nextId.charAt(0).toUpperCase()}${appearance.nextId.slice(1)}SVG`]" class="flex h-9 w-9 items-center justify-center" />
        </div>
    </Button>
</template>
