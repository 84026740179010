<script lang="ts" setup>
// Dependencies - Vendor
import { onClickOutside } from '@vueuse/core';
import { type PropType, ref } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';

// Dependencies - Component
import Button from '@/components/Button.vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineProps({
    items: { required: true, type: Array as PropType<Record<string, unknown>[]> }
});
const emit = defineEmits(['click']);

// Reactive Elements
const wrapperRef = ref<HTMLDivElement | null>(null);

// Reactive Variables & Watchers
const dropdownMenuIsVisible = ref(false);

// UI Event Handlers- Dropdown Menu Item - Select
const selectDropdownMenuItem = (itemIndex: number, item: Record<string, unknown>): void => {
    emit('click', itemIndex, item);
    dropdownMenuIsVisible.value = false;
};

// UI Event Handlers - Wrapper - On Click Outside
onClickOutside(wrapperRef, (): void => void (dropdownMenuIsVisible.value = false));
</script>

<template>
    <div ref="wrapperRef" class="relative">
        <!-- Dropdown Button -->
        <Button id="dropdownButton" class="rounded-l-none" type="dropdown" @click.stop="() => (dropdownMenuIsVisible = !dropdownMenuIsVisible)">
            <div v-html="actionIcons.toggleDropdownSVG" class="h-4 w-4" />
        </Button>

        <!-- Dropdown Menu -->
        <div v-if="dropdownMenuIsVisible" class="absolute right-0 z-10 mt-1 rounded border border-line-light bg-surface-light dark:border-line-dark dark:bg-gray-700 dark:bg-surface-dark">
            <ul class="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
                <li v-for="(item, itemIndex) in items" :key="itemIndex">
                    <div class="cursor-pointer whitespace-nowrap px-3 py-2 hover:bg-gray-200" @click="(): void => selectDropdownMenuItem(itemIndex, item)">
                        {{ item.label }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
