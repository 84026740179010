// Interfaces/Types - Workbench Configuration
export interface WorkbenchConfig {
    benchtops: BenchtopConfig[];
}

// Interfaces/Types - Benchtop Configuration
export interface BenchtopConfig {
    id: string;
    options: OptionConfig[];
}

// Interfaces/Types - Option Configuration
export interface OptionConfig {
    id: string;
    isNewGroup?: boolean;
    to: string;
}

// Workbench Configuration Data
export const workbenchConfigData = {
    benchtops: [
        { id: 'administrator', options: [{ id: 'administrator', to: '/administrator' }] },
        { id: 'partner', options: [{ id: 'partner', to: '/partner' }] },
        {
            id: 'workflow',
            options: [
                { id: 'home', to: '/workflow' },
                { id: 'establishDataViews', isNewGroup: true, to: '/workflow/establishDataViews' },
                { id: 'assembleDimensions', to: '/workflow/assembleDimensions' },
                { id: 'contextualiseData', to: '/workflow/contextualiseData' },
                { id: 'exploreResults', to: '/workflow/exploreResults' },
                { id: 'buildDataApps', to: '/workflow/buildDataApps' },
                { id: 'manageComponents', isNewGroup: true, to: '/workflow/manageComponents' }
            ]
        }
    ]
};
