// Dependencies - Vendor
import type { App } from 'vue';

// Dependencies - Data
import englishTranslations from '@/locales/en.json';

// Interfaces/Types - Component Custom Properties
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t(id: string, params?: Record<string, number | string>): string;
    }
}

// Module Constants & Module Variables
const supportedLanguageIds = ['en', 'es'];
let translations: Record<string, string>;

// Utilities - Initialise Translations
export const initTranslations = async (app: App<Element>): Promise<void> => {
    translations = await loadTranslations(establishPreferredLanguageId());
    app.config.globalProperties.$t = t;
};

// Utilities - Translate
export const t = (id: string, params?: Record<string, number | string>): string => {
    const text = translations[id];
    if (!text) return `??:${id}`;
    return text.replace(/{(\w+)}|{('.*?')}/g, (match, paramId, fixedString) => {
        if (fixedString) return fixedString.slice(1, -1); // Remove surrounding quotes and return fixed string.
        return params && paramId in params ? String(params[paramId]) : paramId; // Return parameter value or parameter identifier if not found.
    });
};

// Utilities - Load Translations
export const loadTranslations = async (id: string): Promise<Record<string, string>> => {
    if (id === 'es') return (await import('@/locales/es.json')).default;
    return englishTranslations;
};

// Utilities - Establish Preferred Language Identifier
const establishPreferredLanguageId = (): string => {
    // Check for exact language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.toLowerCase();
        if (supportedLanguageIds.includes(transformedLanguageId)) return transformedLanguageId;
    }
    // Check for neutral language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.split('-')[0].toLowerCase();
        if (supportedLanguageIds.includes(transformedLanguageId)) return transformedLanguageId;
    }
    return 'en'; // Default to english.
};
