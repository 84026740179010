<script lang="ts" setup>
// Dependencies - Vendor
import { computed, type PropType, useAttrs } from 'vue';

// Interfaces/Types - Button Type Identifier
export type ButtonTypeId = 'alert' | 'default' | 'dropdown' | 'icon' | 'link' | 'option'; // TODO: Card button type for dashboards.

// Attributes, Emitted Events, Options, Properties & Slots
const attrs = useAttrs();
const props = defineProps({
    remainsActive: { default: false, required: false, type: Boolean },
    type: { default: 'default', required: false, type: String as PropType<ButtonTypeId> }
});

// Reactive Variables & Watchers
const isDisabled = computed(() => !!attrs.disabled || attrs.disabled === '');

// Utilities - Build Type Classes
const buildTypeClasses = (): string => {
    const defaultFocusClasses = ' ring-offset-2 focus:outline-none focus:ring-2 focus:ring-neutral-300 focus:ring-offset-surface-light dark:focus:ring-neutral-600 dark:focus:ring-offset-surface-dark';
    // const transitionClasses = 'transition duration-200 ease-in-out';
    if (props.type === 'alert') {
        const baseClasses = 'h-8 rounded px-4 text-sm font-medium';
        const activeClasses = ' active:bg-backdrop-light dark:active:bg-backdrop-dark';
        const backgroundClasses = ' bg-transparent dark:bg-transparent';
        const borderClasses = ' border border-line-light dark:border-line-dark';
        const hoverClasses = isDisabled.value ? '' : ' hover:bg-neutral-200 dark:hover:bg-neutral-700';
        return `${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${defaultFocusClasses}${activeClasses}`;
    } else if (props.type === 'dropdown') {
        const baseClasses = 'h-10 rounded px-3';
        const activeClasses = ' active:bg-backdrop-light dark:active:bg-backdrop-dark';
        const backgroundClasses = ' bg-backdrop-light dark:bg-backdrop-dark';
        const borderClasses = '';
        const hoverClasses = isDisabled.value ? '' : ' hover:bg-neutral-200 dark:hover:bg-neutral-700';
        return `${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${defaultFocusClasses}${activeClasses}`;
    } else if (props.type === 'icon') {
        const baseClasses = 'rounded text-sm font-medium';
        const activeClasses = ' active:bg-backdrop-light dark:active:bg-backdrop-dark';
        const backgroundClasses = ' bg-transparent dark:bg-transparent';
        const borderClasses = '';
        const hoverClasses = isDisabled.value ? '' : ' hover:bg-neutral-200 dark:hover:bg-neutral-700';
        return ` ${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${defaultFocusClasses}${activeClasses}`;
    } else if (props.type === 'link') {
        const baseClasses = 'rounded text-blue-600 dark:text-blue-400';
        const activeClasses = ' active:text-blue-600 dark:active:text-blue-400';
        const backgroundClasses = ' bg-transparent dark:bg-transparent';
        const borderClasses = '';
        const focusClasses = '';
        const hoverClasses = isDisabled.value ? '' : ' hover:text-blue-800 dark:hover:text-blue-500';
        return ` ${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${focusClasses}${activeClasses}`;
    } else if (props.type === 'option') {
        const baseClasses = 'h-10 rounded px-0.5';
        const activeClasses = ' active:bg-backdrop-light dark:active:bg-backdrop-dark';
        const backgroundClasses = props.remainsActive ? ' bg-neutral-300 dark:bg-neutral-600' : ' bg-backdrop-light dark:bg-backdrop-dark';
        const borderClasses = ' focus:outline-none';
        const hoverClasses = isDisabled.value ? '' : ' hover:bg-neutral-200 dark:hover:bg-neutral-700';
        return `${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${defaultFocusClasses}${activeClasses}`;
    } else {
        const baseClasses = 'h-10 rounded text-sm font-medium leading-tight justify-center';
        const activeClasses = ' active:bg-neutral-200 dark:active:bg-neutral-700';
        const backgroundClasses = props.remainsActive ? ' bg-neutral-300 dark:bg-neutral-700' : ' bg-neutral-200 dark:bg-neutral-700';
        const borderClasses = '';
        const hoverClasses = isDisabled.value ? '' : ' hover:bg-neutral-300 dark:hover:bg-neutral-600';
        return `${baseClasses}${backgroundClasses}${borderClasses}${hoverClasses}${defaultFocusClasses}${activeClasses}`;
    }
};
</script>

<template>
    <button class="flex items-center" :class="`${buildTypeClasses()} ${isDisabled ? 'opacity-40' : 'opacity-100'}`" type="button">
        <slot />
    </button>
</template>
