<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Dependencies - Framework
import { authIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Button from '@/components/Button.vue';
import CircularSpinner from '@/components/CircularSpinner.vue';
import StatusIcon from '@/components/StatusIcon.vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineProps({
    labelsAreVisible: { required: true, type: Boolean }
});
defineEmits(['navigateToBenchtop', 'navigateToOption']);

// Global State
const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed(() => mainStore.internetConnectionIsOnline);
const optionDrawerStateId = computed(() => mainStore.optionDrawerStateId);
const sessionUser = computed(() => mainStore.sessionUser);

// UI Event Handlers - Show Session Dialog
const showSessionAuthDialog = () => {
    if (optionDrawerStateId.value === 'floating') mainStore.collapseOptionDrawer();
    router.push({ query: { ...route.query, state: 'auth' } });
};
</script>

<template>
    <div class="flex flex-none flex-col pb-5">
        <!-- Divider -->
        <hr class="flex-none border-line-light dark:border-line-dark" />

        <!-- Session Button -->
        <!-- TODO: Icons flash... -->
        <div v-if="sessionUser === undefined" class="mt-2 flex h-10 flex-none items-center gap-x-2 px-0.5">
            <div class="flex h-9 w-9 flex-none items-center justify-center"><CircularSpinner /></div>
            <div v-if="labelsAreVisible" class="pr-2 text-left text-base font-normal text-caption-light dark:text-caption-dark">
                {{ $t('account.loading.message') }}
            </div>
        </div>
        <Button v-else class="mt-2 gap-x-2" :colorId="'success'" type="option" @click="showSessionAuthDialog()">
            <div class="relative">
                <div v-html="authIcons[`${sessionUser ? 'signOut' : 'signInUp'}SVG`]" class="flex h-9 w-9 flex-none items-center justify-center" />
                <StatusIcon v-if="!internetConnectionIsOnline" class="absolute -right-1 -top-1" :colorId="'warning'" />
            </div>
            <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $t(`account.${sessionUser ? 'manage' : 'identify'}.label`) }}</div>
        </Button>
    </div>
</template>
