<script lang="ts" setup>
// Dependencies - Vendor
import { computed, onErrorCaptured, onMounted, watch } from 'vue';
import { useNetwork, useResizeObserver } from '@vueuse/core';

// Dependencies - Framework
import { t } from '@/locales';
import { useMainStore } from '@/stores/mainStore';
import { handleError, reloadWorkbench } from '@/globals';

// Dependencies - Component
import ComponentLoadingMask from '@/components/ComponentLoadingMask.vue';

// Dependencies - Data
import { version } from './config.json';

// Global State
const mainStore = useMainStore();

// Module Constants
const ALERT_EXPIRES_IN = 5000;
const TIMEOUT_DELAY = 5000;

// Non-Reactive Variables
let socket: WebSocket | undefined = undefined;

// Reactive Variables & Watchers - Network Connection Status
const { isOnline } = useNetwork(); // Reactive reference to the browsers network information object.
watch(isOnline, (newValue, oldValue) => {
    // Check if the network connection online status has shifted from false to true (the network connection is now online).
    if (oldValue === false && newValue === true) {
        // The network connection is online; however, this does not mean we have an active internet connection, so attempt to access the Data Positioning Operations service.
        // The Application Monitor (appMonitor/index.ts) monitors all internet requests and will update the internet connection status reactive reference (see below) accordingly.
        // We do not need to wait for the fetch to finish and can ignore any errors.
        fetch('https://operations.datapositioning.app/config').catch(() => {});
        return;
    }

    // The network connection is offline.
    mainStore.internetConnectionIsOnline = false;
});

// Reactive Variables & Watchers - Internet Connection Status
const internetConnectionIsOnline = computed(() => mainStore.internetConnectionIsOnline); // Reactive reference to the internet connection status.
watch(internetConnectionIsOnline, (newValue, oldValue) => {
    // Check if the internet connection online status was unset (the application is loading) and is now true (the internet connection is online).
    if (oldValue === undefined && newValue) {
        // No need to display an internet connection alert or reconnect to the Services WebSocket.
        return;
    }

    // Remove any active internet connection alert so we can replace it with one showing the new status.
    mainStore.alertConfigs = mainStore.alertConfigs.filter((alert) => alert.categoryId !== 'offline' && alert.categoryId !== 'online');

    // Check if the internet connection online status has shifted from false to true (the internet connection is now online).
    if (oldValue === false && newValue === true) {
        // The internet connection is online. Connect to the Services WebSocket and show the online alert.
        if (!socket || (socket && (socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING))) {
            connectToServicesWebSocket();
        }
        mainStore.showAlert({ categoryId: 'online', expiresIn: ALERT_EXPIRES_IN, message: t('internetConnection.online.message'), typeId: 'success' });
        return;
    }

    // Show the offline alert.
    mainStore.showAlert({ categoryId: 'offline', message: t('internetConnection.offline.message'), typeId: 'warning' });
});

// Reactive Variables & Watchers - Workbench
const workbenchIsEUInstance = computed(() => mainStore.workbenchIsEUInstance);
watch(workbenchIsEUInstance, (newValue) => (newValue === undefined ? void 0 : connectToServicesWebSocket()));
const workbenchIsLoading = computed(() => mainStore.workbenchIsLoading);

// Lifecycle Event Handlers
onErrorCaptured((error) => handleError(error, { locator: 'App 1' }));
onMounted(() => useResizeObserver(document.getElementById('app'), (entries) => mainStore.handleWindowWidthChange(entries[0].contentRect.width)));

// UI Event Handlers - Window/Document - Before Unload - This is triggered when the current window, contained document, and associated resources are about to be unloaded.
window.addEventListener('beforeunload', (event) => {
    // Check if updates are pending.
    if (mainStore.sessionUpdatesArePending) {
        // Updates are pending. Trigger browser alert allowing user to cancel unload.
        event.preventDefault();
    }
});

// Utilities - Compare Version Strings
const compareVersionStrings = (left: string, right: string) => {
    const leftSegments = left.split('.').map(Number);
    const rightSegments = right.split('.').map(Number);
    for (let i = 0; i < Math.max(leftSegments.length, rightSegments.length); i++) {
        const leftSegment = leftSegments[i] || 0;
        const rightSegment = rightSegments[i] || 0;
        if (leftSegment > rightSegment) {
            return 1;
        }
        if (leftSegment < rightSegment) {
            return -1;
        }
    }
    return 0;
};

// Utilities - Connect Services WebSocket
const connectToServicesWebSocket = () => {
    try {
        const wsURL = `wss://operations.datapositioning.app/services/websocket`;
        console.log(`Attempting to connect to WebSocket: ${wsURL}...`);
        socket = new WebSocket(wsURL);
        socket.onopen = () => console.log('WebSocket connection established.');
        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                const workbenchSession = data.find((session: Record<string, unknown>) => session.id === `workbench-${mainStore.workbenchIsEUInstance ? 'eu' : 'global'}`);
                if (!workbenchSession) {
                    return;
                }
                const newVersion = workbenchSession.data.version;
                if (compareVersionStrings(mainStore.workbenchLastDeployedVersion || version, newVersion) === -1) {
                    mainStore.workbenchLastDeployedVersion = newVersion;
                    // If installed version, or last seen version, is less than new version then show install alert.
                    mainStore.showAlert({
                        actionLabel: t('action.reload.label'),
                        actionCallback: async () => reloadWorkbench(),
                        message: t('versionManagement.available.message', { newVersion: newVersion }),
                        notes: t('versionManagement.available.notes', { currentVersion: version }),
                        typeId: 'info'
                    });
                }
            } catch (error) {
                console.log('Error parsing message.', error);
            }
        };
        socket.onclose = (event) => {
            console.log(`WebSocket closed. Code: ${event.code}.`);
            socket = undefined;
            setTimeout(connectToServicesWebSocket, TIMEOUT_DELAY);
        };
        socket.onerror = (error) => {
            // TODO: Try and reconnect a limited number of times.
            console.log('Error occurred. Check error log for details.', error);
        };
    } catch (error) {
        console.log('Failed to create WebSocket. Check error log for details.', error);
    }
};
</script>

<template>
    <!-- Application Loading Mask -->
    <Transition name="fade">
        <ComponentLoadingMask v-if="workbenchIsLoading" typeLabel="Application" />
    </Transition>

    <!-- Top Level Router View -->
    <RouterView />
</template>
