// Dependencies - Vendor
import type { RouteLocationNormalizedGeneric } from 'vue-router';

// Dependencies - Framework
import type { ErrorContext } from '@datapos/datapos-share-core';
import type { PendingError } from '@/appMonitor';

// Interfaces/Types - Globals
interface Globals {
    appMonitor: typeof import('@/appMonitor') | undefined;
    pendingErrors: PendingError[];
}

// Global Constants
export const DEFINE_ASYNC_COMPONENT_DELAY = 200;
export const DEFINE_ASYNC_COMPONENT_TIMEOUT = 5000;
export const DISPLAY_SKELETON_DELAY = 200;

// Global Variables
export const globals: Globals = {
    appMonitor: undefined,
    pendingErrors: []
};

// Helpers - Format Size in GB
export const formatSizeInGB = (value: number) => `${(value / 1024 / 1024 / 1024).toFixed(2)} GB`;

// Helpers - Application Monitor - Initialise
export const initAppMonitor = async (module: typeof import('@/appMonitor')): Promise<void> => {
    await module.initialise();
    globals.appMonitor = module;
};

// Helpers - Application Manager - Handle Error
export const handleError = (error?: unknown, context?: ErrorContext): boolean => {
    try {
        if (globals.appMonitor) {
            return globals.appMonitor.reportError(error, context);
        }
    } catch (error) {
        // Ignore error. Most likely application is not fully initialised. Will fall through to following code that adds error to pending errors for reporting at a later time.
    }
    globals.pendingErrors.push({ error, context });
    return false;
};

// Helpers - Application Manager - Track Page View Event
export const trackPageViewEvent = (to: RouteLocationNormalizedGeneric): void => {
    if (globals.appMonitor) {
        globals.appMonitor.trackPageViewEvent(to);
    }
};

// Helpers - UI - Navigate to External Link
export const navigateToExternalLink = (url: string): void => {
    window.open(url, '_blank');
};

// Helpers - UI - Reload Workbench
export const reloadWorkbench = () => {
    const appURL = new URL(window.location.href);
    const params = appURL.searchParams;
    params.delete('reload-timestamp');
    params.set('reload-timestamp', String(new Date().getTime()));
    window.location.href = appURL.toString();
};
