// About Icons
export const aboutIcons: { [key: string]: string } = {
    // Application - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:developer_mode:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=developer+&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    applicationSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M345-337 202-480l143-143 18.5 18-125 125 125 125-18.5 18Zm-89 55.5h25.5v66.5h397v-66.5H704v127.24q0 24.2-16.53 40.73Q670.94-97 646.72-97H313.28q-24.22 0-40.75-16.53T256-154.26V-281.5Zm25.5-397H256v-127.24q0-24.2 16.53-40.73Q289.06-863 313.28-863h333.44q24.22 0 40.75 16.53T704-805.74v127.24h-25.5V-745h-397v66.5Zm0 489v35q0 12 10 22t22 10h333q12 0 22-10t10-22v-35h-397Zm0-581h397v-35q0-12-10-22t-22-10h-333q-12 0-22 10t-10 22v35ZM615-337l-18.5-18 125-125-125-125 18.5-18 143 143-143 143ZM281.5-770.5v-67 67Zm0 581v67-67Z"/></svg>',
    // Device/Browser - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:devices:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=device&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    deviceBrowserSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M137-217v-38.5h80v-430q0-23.72 16.89-40.61T274.5-743H783v25.5H274.5q-14 0-23 9t-9 23v430H457v38.5H137Zm466.5 0q-12.25 0-20.62-8.38-8.38-8.37-8.38-20.62v-345.5q0-11.67 8.38-20.09 8.37-8.41 20.62-8.41h192.2q11.55 0 19.93 8.41 8.37 8.42 8.37 20.09V-246q0 12.25-8.41 20.62-8.42 8.38-20.09 8.38h-192Zm-3.5-38.5h198.5v-339H600v339Zm0 0h198.5H600Z"/></svg>',
    // Internet Connection - Google Fonts:
    internetConnectionSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M479.93-156.5q-19.93 0-33.68-13.82t-13.75-33.75q0-19.43 13.82-33.43t33.75-14q19.93 0 33.68 14.07t13.75 33.5q0 19.93-13.82 33.68t-33.75 13.75ZM256-350l-25.5-24.5q49-49 112.42-76.75Q406.33-479 480.17-479q73.83 0 137.08 27.5Q680.5-424 729.5-375l-25 25q-44-43.5-101.56-68.25-57.57-24.75-123-24.75-65.44 0-122.94 24.75T256-350ZM91.5-514.5l-25.5-25q79.5-79.5 184.78-125.75t229.25-46.25q123.97 0 229.22 46.25T894-539.5l-25 25Q793-590 693.6-632.75T480.1-675.5q-114.1 0-213.6 42.75T91.5-514.5Z"/></svg>',
    // Location - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:globe:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=globe+&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    locationSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M480.24-137q-71.15 0-133.87-27.02-62.72-27.03-109.12-73.35-46.4-46.33-73.32-108.83Q137-408.7 137-479.76q0-71.15 27.02-133.87 27.03-62.72 73.35-109.12 46.33-46.4 108.83-73.32Q408.7-823 479.76-823q71.15 0 133.87 27.02 62.72 27.03 109.12 73.35 46.4 46.33 73.32 108.83Q823-551.3 823-480.24q0 71.15-27.02 133.87-27.03 62.72-73.35 109.12-46.33 46.4-108.83 73.32Q551.3-137 480.24-137Zm-.24-25.5q132.55 0 225.02-92.46 92.48-92.46 92.48-225.11v-13.68q0-6.75-1-14.25-1.87 25.7-19.11 42.1-17.25 16.4-42.89 16.4H627.44q-26.22 0-44.58-18.3-18.36-18.31-18.36-44.44v-31.68h-126v-63.07q0-26.01 18.34-44.6 18.35-18.58 44.48-18.58H533v-40q0-30.83 21-47.08t49.5-16.25q-29-12-59.92-18-30.93-6-63.58-6-132.55 0-225.02 92.48Q162.5-612.55 162.5-480v15q0 7 1 15h162q53.17 0 89.59 36.41 36.41 36.42 36.41 89.14v31.95H357V-187q28 11.5 59.27 18 31.28 6.5 63.73 6.5Z"/></svg>',
    // Team - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:group:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=group&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    teamSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M130.5-238.5v-49q0-25.64 13.02-42.43 13.03-16.79 35.95-27.67 48.03-22.9 94.78-37.15T394-409q73 0 119.5 14.25t95.21 37.16q22.79 11.09 35.79 27.81t13 42.28v49h-527Zm606.5 0V-287q0-31.5-11.2-58.73-11.19-27.23-30.3-46.77 21.5 6.5 42.47 15.4 20.97 8.89 43.53 19.6 22 11 35.25 29.66T830-287v48.5h-93Zm-343-273q-43.79 0-74.39-30.61Q289-572.71 289-616.5t30.61-74.39q30.6-30.61 74.39-30.61t74.39 30.61Q499-660.29 499-616.5t-30.61 74.39Q437.79-511.5 394-511.5Zm249-105.31q0 43.39-30.61 74.1Q581.79-512 538-512q.5 0 1.26.16.75.15 1.24.34 18.34-21.95 27.92-48.91 9.58-26.96 9.58-56.46 0-29.16-10.5-55.39-10.5-26.24-27-49.74-.5 0-1 .25t-1.5.25q43.79 0 74.39 30.75Q643-660 643-616.81ZM156-264h476v-23.37q0-15.32-7.5-26.22Q617-324.5 596-336q-42.06-23-89.3-35.25-47.25-12.25-112.48-12.25-65.4 0-112.81 12.25T192-336q-21.5 11.5-28.75 22.5t-7.25 26v23.5Zm237.94-273q33.06 0 56.31-23.19t23.25-56.25q0-33.06-23.19-56.31T394.06-696q-33.06 0-56.31 23.19t-23.25 56.25q0 33.06 23.19 56.31T393.94-537Zm.06 273Zm0-352.5Z"/></svg>'
};

// Action Icons
export const actionIcons: { [key: string]: string } = {
    // Add - Google Fonts: https://fonts.google.com/icons?icon.query=sa&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    addSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M468.5-469H257v-23h211.5v-211.5h23V-492H703v23H491.5v211.5h-23V-469Z"/></svg>',
    // Appear Dark - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:dark_mode:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=dark+mode+&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    appearDarkSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M482.91-173.5q-127.74 0-217.08-89.42-89.33-89.42-89.33-217.17 0-112.41 71.02-196.41T425-782h-3q-13.5 28-20.75 58.75T394-660.19q0 112.4 78.46 190.79Q550.92-391 663-391q32.38 0 62.86-7.29 30.48-7.3 58.14-20.71v-3q-20.5 106.46-104.59 177.48t-196.5 71.02Zm.23-25.5q88.36 0 158.86-49.25Q712.5-297.5 745-377q-20.83 5.45-41.42 8.48-20.58 3.02-40.49 3.02-122.41 0-208.5-86.09t-86.09-208.5q0-19.91 3.02-40.49 3.03-20.59 8.48-41.42-79.5 32.5-128.75 103.01Q202-568.47 202-479.83q0 116.37 82.33 198.6Q366.67-199 483.14-199Zm-9.64-271.5Z"/></svg>',
    // Appear Light - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:light_mode:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=brightness+&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    appearLightSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M480.06-359q50.44 0 85.94-35.31t35.5-85.75q0-50.44-35.41-85.94t-86-35.5q-50.59 0-85.84 35.41t-35.25 86q0 50.59 35.31 85.84T480.06-359Zm0 25.5q-61.56 0-104.06-42.59-42.5-42.58-42.5-104 0-61.41 42.59-104.16 42.58-42.75 104-42.75 61.41 0 104.16 42.69T627-480.06q0 61.56-42.69 104.06t-104.25 42.5ZM199-467.5H67.5V-493H199v25.5Zm694 0H761.5V-493H893v25.5Zm-425.5-294V-893H493v131.5h-25.5Zm0 694V-199H493v131.5h-25.5ZM274-670l-80.5-78 17.5-19.5 80 79.5-17 18Zm475 477.5L670-272l16-18.5 80.5 78.5-17.5 19.5ZM670-686l78-80.5 19.5 17.5-79.5 79.5-18-16.5ZM192.5-211l79.5-79.5 16.5 16.5-77 81-19-18ZM480-480Z"/></svg>',
    // Appear System - Google Fonts: https://fonts.google.com/icons?icon.query=brightness+&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    appearSystemSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M334-321h28.5l44.15-108h148.7l44.15 108H630L487-678.5h-12L334-321Zm82-133 62-163h4l64 163H416Zm64 347.5L369.91-217H217v-152.91L106.5-480 217-590.09V-743h152.91L480-853.5 590.09-743H743v152.91L853.5-480 743-369.91V-217H590.09L480-106.5Zm0-36.5 99.5-99.5h138v-138L817-480l-99.5-99.5v-138h-138L480-817l-99.5 99.5h-138v138L143-480l99.5 99.5v138h138L480-143Zm0-337Z"/></svg>',
    // Back Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:arrow_back:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=back&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    // backSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m266.5-467.5 232 232L480-217 217-480l263-263 18.5 18.5-232 231.5H743v25.5H266.5Z"/></svg>',
    // Cancel - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:cancel:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=cancel&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    cancelSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m337-319 143-143 143 143 18-18-143-143 143-143-18-18-143 143-143-143-18 18 143 143-143 143 18 18Zm143.24 182q-71.15 0-133.87-27.02-62.72-27.03-109.12-73.35-46.4-46.33-73.32-108.83Q137-408.7 137-479.76q0-71.15 27.02-133.87 27.03-62.72 73.35-109.12 46.33-46.4 108.83-73.32Q408.7-823 479.76-823q71.15 0 133.87 27.02 62.72 27.03 109.12 73.35 46.4 46.33 73.32 108.83Q823-551.3 823-480.24q0 71.15-27.02 133.87-27.03 62.72-73.35 109.12-46.33 46.4-108.83 73.32Q551.3-137 480.24-137Zm-.27-25.5q132.53 0 225.03-92.47 92.5-92.46 92.5-225 0-132.53-92.47-225.03-92.46-92.5-225-92.5-132.53 0-225.03 92.47-92.5 92.46-92.5 225 0 132.53 92.47 225.03 92.46 92.5 225 92.5ZM480-480Z"/></svg>',
    // Close - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:close:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=cancel&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    closeSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m257.5-239-18-18.5 222-222.5-222-222.5 18-18.5L480-498.5 702.5-721l18 18.5-222 222.5 222 222.5-18 18.5L480-461.5 257.5-239Z"/></svg>',
    // Collapse - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:collapse_content:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=collapse+c&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    collapseSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M439-439v185.5h-25.5v-160h-160V-439H439Zm108-268v160h160v25.5H521.5V-707H547Z"/></svg>',
    // Enter Fullscreen - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:fullscreen:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=full&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    enterFullscreenSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M177-177v-167h25.5v141.5H344v25.5H177Zm440.5 0v-25.5h141V-344H784v167H617.5ZM177-616.5V-783h167v25.5H202.5v141H177Zm581.5 0v-141h-141V-783H784v166.5h-25.5Z"/></svg>',
    // Exit Fullscreen - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:fullscreen_exit:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=full&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    exitFullscreenSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M318.5-177v-141.5H177V-344h167v167h-25.5Zm299 0v-167H784v25.5H643V-177h-25.5ZM177-616.5V-642h141.5v-141H344v166.5H177Zm440.5 0V-783H643v141h141v25.5H617.5Z"/></svg>',
    // Expand - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:expand_content:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=expand+c&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    expandSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M253.5-253.5V-439H279v160h160v25.5H253.5Zm428-268v-160h-160V-707H707v185.5h-25.5Z"/></svg>',
    // Save - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:save:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=save&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    saveSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M783.5-655.5v421.68q0 24.26-16.53 40.79-16.53 16.53-40.73 16.53H233.76q-24.2 0-40.73-16.53-16.53-16.53-16.53-40.73v-492.48q0-24.2 16.53-40.73 16.53-16.53 40.79-16.53H655.5l128 128ZM758-645.19 645.19-758H234q-14 0-23 9t-9 23v492q0 14 9 23t23 9h492q14 0 23-9t9-23v-411.19ZM480-319.5q26.75 0 45.88-19.13Q545-357.75 545-384.5q0-26.75-19.12-45.88Q506.75-449.5 480-449.5q-26.75 0-45.87 19.12Q415-411.25 415-384.5q0 26.75 19.13 45.87Q453.25-319.5 480-319.5Zm-197.5-253H556v-105H282.5v105ZM202-645.19V-202v-556 112.81Z"/></svg>',
    // Toggle - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:menu:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=menu&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    toggleSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M177-282v-25.5h606v25.5H177Zm0-185.5V-493h606v25.5H177Zm0-185.5v-25.5h606v25.5H177Z"/></svg>',
    // Toggle Chat - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:forum:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=chat&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    toggleChatSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M293.92-337.5q-11.08 0-19.25-8.05T266.5-365v-28H714l33 33v-358.5h28q11.4 0 19.45 8.05 8.05 8.05 8.05 19.23v462.72l-109-109H293.92Zm-136.42-24v-434.71q0-11.19 8.05-19.24 8.05-8.05 19.45-8.05h457q11.4 0 19.45 8.05 8.05 8.05 8.05 19.25v298.4q0 11.2-8.05 19.25T642-470.5H266.5l-109 109ZM644-496v-302H183v374l72-72h389Zm-461 0v-302 302Z"/></svg>',
    // Toggle Documentation - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:library_books:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=library&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    toggleDocumentationSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M397-428.5h138V-454H397v25.5Zm0-116.5h267.5v-25.5H397v25.5Zm0-116h267.5v-25.5H397v25.5Zm-73.5 369q-24.44 0-40.97-16.53Q266-325.06 266-349.5v-416q0-24.44 16.53-40.97Q299.06-823 323.5-823h416q24.44 0 40.97 16.53Q797-789.94 797-765.5v416q0 24.44-16.53 40.97Q763.94-292 739.5-292h-416Zm0-25.5h416q12 0 22-10t10-22v-416q0-12-10-22t-22-10h-416q-12 0-22 10t-10 22v416q0 12 10 22t22 10ZM220.5-189q-24.44 0-40.97-16.53Q163-222.06 163-246.5V-688h25.5v441.5q0 12 10 22t22 10H662v25.5H220.5Zm71-608.5v480-480Z"/></svg>',
    // Toggle Dropdown - Flowbite
    toggleDropdownSVG: '<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" width="100%"><path d="M19 9l-7 7-7-7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></svg>'
    // Toggle Index - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:format_list_bulleted:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=notes&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    // toggleIndex:
    //     '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M390-230v-25.5h393.5v25.5H390Zm0-237.5V-493h393.5v25.5H390Zm0-237.5v-25.5h393.5v25.5H390ZM219.39-199.5q-17.45 0-30.17-12.82-12.72-12.83-12.72-30.29 0-17.45 12.82-30.17 12.83-12.72 30.29-12.72 17.45 0 30.17 12.82 12.72 12.83 12.72 30.29 0 17.45-12.82 30.17-12.83 12.72-30.29 12.72Zm0-238q-17.45 0-30.17-12.59-12.72-12.6-12.72-30.29 0-17.68 12.82-30.4 12.83-12.72 30.29-12.72 17.45 0 30.17 12.82 12.72 12.83 12.72 30.52 0 17.68-12.82 30.17-12.83 12.49-30.29 12.49Zm0-237q-17.45 0-30.17-12.82-12.72-12.83-12.72-30.29 0-17.45 12.82-30.17 12.83-12.72 30.29-12.72 17.45 0 30.17 12.82 12.72 12.83 12.72 30.29 0 17.45-12.82 30.17-12.83 12.72-30.29 12.72Z"/></svg>',
    // Lock Workbench - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:lock:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=lock&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    // lockWorkbenchSVG:
    //     '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M274-137q-23.97 0-40.73-16.77-16.77-16.76-16.77-40.73v-333q0-23.97 16.77-40.73Q250.03-585 274-585h61.5v-78.77q0-60.73 42-102.73 42-42 102.5-42t102.5 42q42 42 42 102.73V-585H686q23.97 0 40.73 16.77 16.77 16.76 16.77 40.73v333q0 23.97-16.77 40.73Q709.97-137 686-137H274Zm0-25.5h412q14 0 23-9t9-23v-333q0-14-9-23t-23-9H274q-14 0-23 9t-9 23v333q0 14 9 23t23 9Zm205.92-146q22.34 0 37.46-15.03 15.12-15.04 15.12-37.39 0-22.34-15.03-37.46-15.04-15.12-37.39-15.12-22.34 0-37.46 15.03-15.12 15.04-15.12 37.39 0 22.34 15.03 37.46 15.04 15.12 37.39 15.12ZM361-585h238v-79.08q0-49.42-34.69-84.17T480.06-783q-49.56 0-84.31 34.77Q361-713.47 361-663.8v78.8ZM242-162.5v-397 397Z"/></svg>'
};

// Alert Icons
export const alertIcons: { [key: string]: string } = {
    // Close - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:close:FILL@1;wght@100;GRAD@200;opsz@24&icon.query=close&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368&icon.style=Outlined
    closeSVG: '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m252-212-38-40 227-228-227-230 38-40 229 230 227-230 38 40-227 230 227 228-38 40-227-230-229 230Z"/></svg>',
    // Error Solid - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:cancel:FILL@1;wght@700;GRAD@200;opsz@24&icon.query=circle&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368&icon.style=Outlined
    errorSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m346-272 134-134 134 134 74-74-134-134 134-134-74-74-134 134-134-134-74 74 134 134-134 134 74 74ZM480-34q-92.64 0-174.47-34.6-81.82-34.61-142.07-94.86T68.6-305.53Q34-387.36 34-480q0-92.9 34.66-174.45 34.67-81.55 95.02-141.97 60.35-60.41 142.07-95Q387.48-926 480-926q92.89 0 174.43 34.58 81.54 34.59 141.97 95.02 60.43 60.43 95.02 142Q926-572.83 926-479.92q0 92.92-34.58 174.41-34.59 81.48-95 141.83Q736-103.33 654.45-68.66 572.9-34 480-34Z"/></svg>',
    // Info Solid - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:info:FILL@1;wght@700;GRAD@200;opsz@24&icon.query=info&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368&icon.style=Outlined
    infoSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M420-259h120v-261H420v261Zm59.82-327q27.68 0 45.93-18.07Q544-622.15 544-649.82q0-27.68-18.07-45.93Q507.85-714 480.18-714q-27.68 0-45.93 18.07Q416-677.85 416-650.18q0 27.68 18.07 45.93Q452.15-586 479.82-586ZM480-34q-92.64 0-174.47-34.6-81.82-34.61-142.07-94.86T68.6-305.53Q34-387.36 34-480q0-92.9 34.66-174.45 34.67-81.55 95.18-141.94 60.51-60.39 142.07-95Q387.48-926 480-926q92.89 0 174.48 34.59 81.59 34.6 141.96 94.97 60.37 60.37 94.97 141.99Q926-572.83 926-479.92q0 92.92-34.61 174.25-34.61 81.32-95 141.83Q736-103.33 654.45-68.66 572.9-34 480-34Z"/></svg>',
    // Success Solid - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:check_circle:FILL@1;wght@700;GRAD@200;opsz@24&icon.query=tick&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368&icon.style=Outlined
    successSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="m421-268 305-306-89-91-216 216-103-103-90 91 193 193Zm59 234q-92.64 0-174.47-34.6-81.82-34.61-142.07-94.86T68.6-305.53Q34-387.36 34-480q0-92.9 34.66-174.45 34.67-81.55 95.02-141.97 60.35-60.41 142.07-95Q387.48-926 480-926q92.89 0 174.43 34.58 81.54 34.59 141.97 95.02 60.43 60.43 95.02 142Q926-572.83 926-479.92q0 92.92-34.58 174.41-34.59 81.48-95 141.83Q736-103.33 654.45-68.66 572.9-34 480-34Z"/></svg>',
    // Warning Solid - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:error:FILL@1;wght@700;GRAD@200;opsz@24&icon.query=error&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368&icon.style=Outlined
    warningSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M479.6-240q31.4 0 52.4-20.6 21-20.59 21-52 0-31.4-20.6-52.9-20.59-21.5-52-21.5-31.4 0-52.4 21.32-21 21.33-21 52.73 0 31.41 20.6 52.18 20.59 20.77 52 20.77ZM412-439h136v-265H412v265Zm68 405q-92.64 0-174.47-34.6-81.82-34.61-142.07-94.86T68.6-305.53Q34-387.36 34-480q0-92.9 34.66-174.45 34.67-81.55 95.18-141.94 60.51-60.39 142.07-95Q387.48-926 480-926q92.89 0 174.48 34.59 81.59 34.6 141.96 94.97 60.37 60.37 94.97 141.99Q926-572.83 926-479.92q0 92.92-34.61 174.25-34.61 81.32-95 141.83Q736-103.33 654.45-68.66 572.9-34 480-34Z"/></svg>'
};

// Authentication Icons
export const authIcons: { [key: string]: string } = {
    // Apple Logo - Font Awesome
    appleSVG:
        '<svg fill="currentColor" height="100%" viewBox="4 32 376.4 447.92" width="100%"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg>',
    // GitHub Logo - Font Awesome
    gitHubSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 0 20 19.51" width="100%"><path d="M10 0C4.476 0 0 4.477 0 10c0 4.418 2.865 8.166 6.84 9.49.5.09.68-.218.68-.483 0-.237-.007-.866-.012-1.7-2.782.603-3.37-1.34-3.37-1.34-.454-1.157-1.11-1.464-1.11-1.464-.907-.62.07-.608.07-.608 1.003.07 1.53 1.03 1.53 1.03.893 1.53 2.342 1.087 2.912.83.09-.645.35-1.085.634-1.335-2.22-.253-4.555-1.11-4.555-4.943 0-1.09.39-1.984 1.03-2.683-.105-.253-.448-1.27.096-2.647 0 0 .84-.268 2.75 1.026A9.555 9.555 0 0110 4.836a9.59 9.59 0 012.504.337c1.91-1.294 2.747-1.026 2.747-1.026.548 1.377.204 2.394.1 2.647.64.7 1.03 1.592 1.03 2.683 0 3.842-2.34 4.687-4.566 4.935.36.308.678.92.678 1.852 0 1.336-.01 2.415-.01 2.743 0 .267.18.578.687.48A10 10 0 0020 10c0-5.522-4.478-10-10-10" fill-rule="evenodd"/></svg>',
    // Google Logo - Font Awesome
    googleSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 0 19.6 20" width="100%"><path d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z" fill="#4285F4" /><path d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z" fill="#34A853" /><path d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z" fill="#FBBC05" /><path d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z" fill="#EA4335" /></svg>',
    // Session Manage Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:person:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=account&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    manageAccountSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M480-511.5q-43.55 0-74.52-30.97Q374.5-573.45 374.5-617t30.98-74.53Q436.45-722.5 480-722.5t74.53 30.97Q585.5-660.55 585.5-617t-30.97 74.53Q523.55-511.5 480-511.5Zm-263.5 273v-49q0-21.61 12.99-40.37 12.99-18.76 35.91-29.53 54.84-25.55 108.47-38.57Q427.5-409 480.02-409q52.51 0 106.25 13 53.73 13 108.45 38.55 22.86 10.79 35.82 29.56t12.96 40.39v49h-527ZM242-264h476v-23.37q0-14.32-9.5-26.72Q699-326.5 682-336q-48.13-23-99.13-35.25-51-12.25-102.93-12.25-52.28 0-103.11 12.25T278.5-336q-17.5 9.5-27 22t-9.5 26.5v23.5Zm238-273q33 0 56.5-23.5T560-617q0-33-23.5-56.5T480-697q-33 0-56.5 23.5T400-617q0 33 23.5 56.5T480-537Zm0-80Zm0 353Z"/></svg>',
    // Microsoft Logo - Font Awesome
    microsoftSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 0 20 20" width="100%"><g fill="none"><path d="M0 0h9.504v9.504H0z" fill="#F25022" /><path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00" /><path d="M0 10.496h9.504V20H0z" fill="#00A4EF" /><path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900" /></g></svg>',
    // Passkey - ????
    passkeySVG:
        '<svg fill="currentColor" height="100%" viewBox="3 1.5 19.5 19" width="100%"><g><circle cx="10.5" cy="6" r="4.5"></circle><path d="M22.5,10.5a3.5,3.5,0,1,0-5,3.15V19L19,20.5,21.5,18,20,16.5,21.5,15l-1.24-1.24A3.5,3.5,0,0,0,22.5,10.5Zm-3.5,0a1,1,0,1,1,1-1A1,1,0,0,1,19,10.5Z"></path><path id="icon-passkey-body" d="M14.44,12.52A6,6,0,0,0,12,12H9a6,6,0,0,0-6,6v2H16V14.49A5.16,5.16,0,0,1,14.44,12.52Z"></path></g></svg>',
    // Sign In/Up - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:login:FILL@0;wght@100;GRAD@-25;opsz@40&icon.query=log&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    signInUpSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M478.5-174.5V-200h247q12 0 22-10t10-22v-493.5q0-12-10-22t-22-10h-247V-783h247.24q24.2 0 40.73 16.53Q783-749.94 783-725.5V-232q0 24.44-16.53 40.97-16.53 16.53-40.73 16.53H478.5Zm-9.5-185L450.5-377l89-89H177v-25.5h362.5l-89-89 18.5-18L588.5-479 469-359.5Z"/></svg>',
    // Sign Out - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:logout:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=log&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    signOutSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M234.26-174.5q-24.2 0-40.73-16.53Q177-207.56 177-232v-493.5q0-24.44 16.53-40.97Q210.06-783 234.26-783H481.5v25.5h-247q-12 0-22 10t-10 22V-232q0 12 10 22t22 10h247v25.5H234.26Zm429.24-185L645-377l89-89H371.5v-25.5H734l-89-89 18.5-18L783-479 663.5-359.5Z"/></svg>'
};

// Empty Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:note_stack_add:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=folder&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
export const emptySVG =
    '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M329.5-194.5V-573q0-23.96 16.62-40.73Q362.74-630.5 387-630.5h378.5q23.97 0 40.73 16.77Q823-596.97 823-573v266.5L654-137H387q-23.97 0-40.73-16.77-16.77-16.76-16.77-40.73ZM138-689.5q-4.5-23.5 9.33-42.83 13.83-19.32 37.67-24.17L557.5-822q23.5-4.5 42.83 9.33 19.32 13.83 24.17 37.67l7.5 47h-24.97L598-775.5q-2-11-12-17.5t-22-4.5l-374.5 66q-14 2.5-21 13.5t-5 24.5l68.5 387v28.5q-11-5.5-18.3-15.78-7.3-10.28-10.2-23.22L138-689.5ZM355-573v378.5q0 14 9 23t23 9h250l160.5-161V-573q0-14-9-23t-23-9H387q-14 0-23 9t-9 23Zm221.5 189Zm-13 133H589v-120h120v-25.5H589v-120h-25.5v120h-120v25.5h120v120Z" /></svg>';

// Option Icons
export const optionIcons: { [key: string]: string } = {
    // Dashboard Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:dashboard:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=dashb&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    dashboardSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M546.5-599v-184.5h237V-599h-237Zm-370 105v-289.5h237V-494h-237Zm370 317.5V-466h237v289.5h-237Zm-370 0V-361h237v184.5h-237Zm25.5-343h186V-758H202v238.5ZM572-202h186v-238.5H572V-202Zm0-422.5h186V-758H572v133.5ZM202-202h186v-133.5H202V-202Zm186-317.5Zm184-105Zm0 184Zm-184 105Z"/></svg>',
    // Establish Data Views Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:table_view:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=table_v&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    establishDataViewsSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M393.5-176q-23.97 0-40.73-16.77Q336-209.53 336-233.5v-333q0-23.97 16.77-40.73Q369.53-624 393.5-624h333q23.97 0 40.73 16.77Q784-590.47 784-566.5v333q0 23.97-16.77 40.73Q750.47-176 726.5-176h-333Zm-32-308h397v-82.5q0-14-9-23t-23-9h-333q-14 0-23 9t-9 23v82.5Zm140 142.5h117v-117h-117v117Zm0 140h117V-316h-117v114.5Zm-140-140H476v-117H361.5v117Zm282.5 0h114.5v-117H644v117Zm-250.5 140H476V-316H361.5v82.5q0 14 9 23t23 9Zm250.5 0h82.5q14 0 23-9t9-23V-316H644v114.5ZM233.5-336q-23.97 0-40.73-16.77Q176-369.53 176-393.5v-333q0-23.97 16.77-40.73Q209.53-784 233.5-784h333q23.97 0 40.73 16.77Q624-750.47 624-726.5v63.5h-25.5v-63.5q0-14-9-23t-23-9h-333q-14 0-23 9t-9 23v333q0 14 9 23t23 9h64v25.5h-64Z"/></svg>',
    // Assemble Dimensions Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:account_tree:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=account_tree&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    assembleDimensionsSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M626.5-150v-119h-159v-397H334v119H110v-263.5h224v119h292.5v-119h224V-547h-224v-119H493v371.5h133.5v-119h224V-150h-224Zm-491-635v212.5V-785ZM652-388v212.5V-388Zm0-397v212.5V-785Zm0 212.5h173V-785H652v212.5Zm0 397h173V-388H652v212.5Zm-516.5-397h173V-785h-173v212.5Z"/></svg>',
    // Contextualise Data Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:format_text_overflow:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=format_text&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    contextualiseDataSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M226.5-187.5V-773H252v585.5h-25.5Zm404-189.5L613-395l72.5-72.5h-311V-493h311L613-565l17.5-18.5 103 103.5-103 103Zm-144 189.5V-345H512v157.5h-25.5Zm0-428V-773H512v157.5h-25.5Z"/></svg>',
    // Explore Results Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:data_exploration:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=data_ex&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    exploreResultsSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M480.14-137q-71.26 0-133.93-27.02-62.68-27.03-109.04-73.35-46.36-46.33-73.27-108.77Q137-408.59 137-479.59q0-71.18 26.89-133.85 26.89-62.68 73.24-108.93 46.34-46.25 108.91-73.44Q408.62-823 479.76-823q71.15 0 133.86 27.17 62.72 27.17 109.12 73.38 46.41 46.22 73.33 108.77Q823-551.12 823-480v284.37q0 24.38-17.33 41.5Q788.34-137 763.55-137H480.14Zm-.14-25.5q132.49 0 224.74-92.48Q797-347.45 797-479.75q0-132.51-92.32-224.88T479.93-797Q347.5-797 255-704.63T162.5-480q0 29.66 5 58.33 5 28.67 16 55.17l136-136.5L452-392l194-194.5H545V-612h145v145h-25.5v-100l-212 210.5-130.5-112L194.5-341q38.5 80 114.95 129.25T480-162.5Zm288.25-2q10.75 0 18.75-8t8-18.5q0-10.5-8-18.75T768.25-218q-10.75 0-18.75 8.25t-8 18.75q0 10.5 8 18.5t18.75 8ZM480.5-480Z"/></svg>',
    // Build Data Apps Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:widgets:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=widget&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    buildDataAppsSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M646-496.5 498.5-644 646-791.5 793.5-644 646-496.5Zm-436-44V-750h209.5v209.5H210ZM540.5-210v-210h210v210h-210ZM210-210v-209.5h209.5V-210H210Zm25.5-356H394v-158.5H235.5V-566ZM647-531l112-112-112-112-112 112 112 112Zm-81 295.5h159v-159H566v159Zm-330.5 0H394V-394H235.5v158.5ZM394-566Zm141-77ZM394-394Zm172-.5Z"/></svg>',
    // Manage Components Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:extension:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=plug&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    manageComponentsSVG:
        '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M361.5-176.5H202q-10.97 0-18.23-7.27-7.27-7.26-7.27-18.23v-159.5q29-13.5 47.25-40.01Q242-428.02 242-460.26q0-32.24-18-58.74t-47.5-40v-159.5q0-10.97 7.27-18.23Q191.03-744 202-744h159q14.5-31 40.59-48.5 26.09-17.5 58.5-17.5t58.66 17.5Q545-775 559.5-744h159q10.97 0 18.23 7.27 7.27 7.26 7.27 18.23v159q31 14.5 48.5 40.75t17.5 58.66q0 32.41-17.5 58.5Q775-375.5 744-361v159q0 10.97-7.27 18.23-7.26 7.27-18.23 7.27H559q-14-31-40.87-48.25Q491.25-242 460-242q-30.5 0-57.5 17.25t-41 48.25ZM202-202h148q15-30 43.5-47.75T460-267.5q38 0 66.75 17.75T570.94-202H718.5v-176H715q36.6-6 54.3-30.4Q787-432.8 787-460q0-27.2-17.7-51.6Q751.6-536 715-542h3.5v-176.5H542v4q-6-36.6-30.4-54.3-24.4-17.7-51.6-17.7-27.2 0-51.6 17.7-24.4 17.7-30.4 54.3v-4H202v145.22q31 17.28 48.25 47.88t17.25 65.49q0 34.91-17.25 65.16T202-348v146Zm258-258Z"/></svg>'
    // Manage Connections Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:database:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=database&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    // manageConnectionsSVG:
    //     '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M480-173.5q-137 0-221.75-32T173.5-290v-390q0-44 89.75-75.5T480-787q127 0 217 31.5t90 75.5v390q0 52.5-85.25 84.5T480-173.5Zm0-435.5q83.5 0 169.5-23t108-51q-21-30-106-54.25T480-761.5q-85 0-171.75 23.25T201-687q19.5 30.5 105.25 54.25T480-609Zm0 204q40.5.5 80.5-3.5t76.5-12.25q36.5-8.25 68.25-20t56.25-26.75v-183q-24.5 15.5-56 27.75t-68.25 20.5Q600.5-594 560.75-590T480-586q-42.5 0-83-4.5t-76.75-12.75q-36.25-8.25-67.25-20t-54-27.25v183q23.5 14.5 54.5 26t67.25 19.75q36.25 8.25 76.5 12.5T480-405Zm0 206q53 0 99.25-5.5t82.5-16q36.25-10.5 62-25.75T761.5-280v-162q-24.5 15-56.25 26.75t-68.25 20Q600.5-387 560.5-383t-80.5 3.5q-42.5 0-83-4.25T320.25-396Q284-404 253-415.75T199-442v162q12 19.5 37.25 34.25t61.5 25.25q36.25 10.5 82.5 16T480-199Z"/></svg>',
    // Manage Contexts Icon - Google Fonts: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:tune:FILL@0;wght@100;GRAD@-25;opsz@24&icon.query=tune&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
    // manageContextsSVG:
    //     '<svg fill="currentColor" height="100%" viewBox="0 -960 960 960" width="100%"><path d="M465.5-150v-184.5H491v79.5h319.5v25.5H491v79.5h-25.5Zm-316-79.5V-255H336v25.5H149.5Zm161-159v-79h-161V-493h161v-79.5H336v184h-25.5Zm155-79V-493h345v25.5h-345Zm158.5-159V-811h25.5v79.5h161v25.5h-161v79.5H624ZM149.5-706v-25.5h345v25.5h-345Z"/></svg>',
};
